/* ----------------- Start Document ----------------- */
(function($){
	"use strict";
	
	$(document).ready(function() {
			
		// Jumping background fix for IE
		if(navigator.userAgent.match(/Trident\/7\./)) { // if IE
			$('body').on("mousewheel", function () {
				event.preventDefault();
	
				var wheelDelta = event.wheelDelta;
				var currentScrollPosition = window.pageYOffset;
				window.scrollTo(0, currentScrollPosition - wheelDelta);
			});
		}
	
			
		/*----------------------------------------------------*/
		/*  Half Screen Map Adjustments
		/*----------------------------------------------------*/
		$(window).on('load resize', function() {
			var winWidth = $(window).width();
			var headerHeight = $("#header-container").height(); // height on which the sticky header will shows
	
			$('.fs-inner-container, .fs-inner-container.map-fixed, #dashboard').css('padding-top', headerHeight);
	
			if(winWidth<992) {
				$('.fs-inner-container.map-fixed').insertBefore('.fs-inner-container.content');
			} else {
				$('.fs-inner-container.content').insertBefore('.fs-inner-container.map-fixed');
			}
	
		});
	

	// ------------------ End Document ------------------ //
	});
	
	})(this.jQuery);
	
	
	/*----------------------------------------------------*/
	/*  Ratings Script
	/*----------------------------------------------------*/
	
	/*  Numerical Script
	/*--------------------------*/
	function numericalRating(ratingElem) {
	
		$(ratingElem).each(function() {
			var dataRating = $(this).attr('data-rating');
	
			// Rules
			if (dataRating >= 4.0) {
				$(this).addClass('high');
			} else if (dataRating >= 3.0) {
				$(this).addClass('mid');
			} else if (dataRating < 3.0) {
				$(this).addClass('low');
			}
	
		});
	
	} numericalRating('.numerical-rating');
	
	